import { useQuery } from '@apollo/client';
import { useContext, useMemo } from 'react';
import { ModelStatusEnum, RolesEnum } from 'src/types';
import { AuthContext } from '../context.component';
import { EditChatModel } from './edit-chat-model.component';
import { GET_CHAT_MODELS_QUERY, GET_PUBLIC_KEY_QUERY } from './queries';
import { ChatModelType, GetChatModelByPageType } from './types';
import Table from '../table';
import { dumpModelsSortParams } from 'src/utils/dump-sort-params.utils';
import { dumpModels } from 'src/helpers/dump.helper';
import { StoreChatModel } from './store-chat-model.component';
import { RowChatters } from './RowChatters';

import styles from './Models.module.css';
import { CREATOR_STATUSES, CREATOR_SUBSCRIPTION } from 'src/constants/creators.constants';
import TableHeader from '../base/tableHeader';
import { checkSortOrder } from 'src/utils/sort.utils';
import { useSearchParamsHelper } from 'src/hooks/useSearchParamsHelper';
import useQueryHook from 'src/hooks/useQueryHook.hook';
import { ChatModelSearchEnum, UserSearchEnum } from 'src/types/search.enum';
import { prepareSearchBySells } from 'src/utils/search.utils';
import { Tag } from 'antd';
import { CopyableElementWrapper } from '../base/copyableElementWrapper ';
import { QueryParamsKeysEnum } from 'src/types/query-params-keys.enum';
import { createSearchParams } from 'react-router-dom';

const MODELS_PAGINATION_LIMIT = 10;

export const AllChatModelsList = () => {
  const { currentUser } = useContext(AuthContext);
  const {
    setPageParams,
    setPaginationLimitParams,
    setSortParams,
    setFilterParams,
    sellsSearch,
    setSellsSearchParams,
    filters,
    searchText,
    currentPage,
    paginationLimit,
    sortField,
    sortOrder,
  } = useSearchParamsHelper();

  const { data, pageData, loading, refetch, error, updateData } = useQueryHook<
    GetChatModelByPageType,
    ChatModelType
  >({
    query: GET_CHAT_MODELS_QUERY,
    variables: {
      limit: paginationLimit ? paginationLimit : MODELS_PAGINATION_LIMIT,
      page: currentPage,
      searchStr: searchText,
      ...filters,
      ...dumpModelsSortParams({ field: sortField, order: sortOrder }),
      ...prepareSearchBySells(sellsSearch),
    },
    dumpFunction: dumpModels,
    fetchPolicy: 'network-only',
  });

  console.log('filters ==>', filters)

  const { data: dataPublicKey } = useQuery<{
    getPubKey: string | null;
  }>(GET_PUBLIC_KEY_QUERY, {
    skip: currentUser?.role !== RolesEnum.SUPER_ADMIN,
  });

  const handleSearcById = (key: string, value: string) => {
    if (value) {
      const valArr = value
        .split(',')
        .map((idValue) => idValue.toString().trim())
        .filter((item) => item);
      const valAsString = JSON.stringify(valArr);

      setSellsSearchParams(key, valAsString);
    } else {
      setSellsSearchParams(key, value);
    }
  };

  const idSearchValue = useMemo(() => {
    let searchStr = '';
    if (sellsSearch?.[ChatModelSearchEnum.ID]) {
      const idsArray = JSON.parse(sellsSearch?.[ChatModelSearchEnum.ID]);

      if (idsArray) {
        if (idsArray.length === 1) {
          searchStr = idsArray[0]?.toString();
        } else {
          idsArray.forEach((val: number | string, idx: number) => {
            if (idx === idsArray.length - 1) {
              searchStr = searchStr + `${val}`;
            } else {
              searchStr = searchStr + `${val}, `;
            }
          });
        }
      }
    }

    return searchStr;
  }, [sellsSearch?.[ChatModelSearchEnum.ID]]);

  const handleNavigateToAuthorWithParams = (searchTextParam: string) => {
    const params = {
      [QueryParamsKeysEnum.SELLS_SEARCH]: JSON.stringify({
        [UserSearchEnum.NAME]: searchTextParam,
    })};

    const searchParams = createSearchParams(params).toString();
    const newTabUrl = `/people?${searchParams}`;

    window.open(newTabUrl, '_blank');
  };

  const COLUMNS = [
    {
      key: 'name',
      dataIndex: 'name',
      title: (
        <TableHeader
          title={'Creator name'}
          search
          key={ChatModelSearchEnum.NAME}
          name={ChatModelSearchEnum.NAME}
          searchValue={sellsSearch?.[ChatModelSearchEnum.NAME]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: 180,
      sorter: true,
      sortOrder: checkSortOrder('name', {
        field: sortField,
        order: sortOrder,
      }),
      render: (__: any, model: ChatModelType) => {
        return (
          <EditChatModel
            className="fcrm-greed-col w-[180px]"
            modelData={{
              ...model,
            }}
            publicKey={dataPublicKey?.getPubKey || null}
            updateCreatorInList={updateData}
          />
        );
      },
    },
    {
      key: 'modelId',
      dataIndex: 'modelId',
      title: (
        <TableHeader
          title={'Creator Id'}
          search
          key={ChatModelSearchEnum.ID}
          name={ChatModelSearchEnum.ID}
          searchValue={idSearchValue}
          setSearch={handleSearcById}
        />
      ),
      width: 150,
      render: (_: any, model: any) => {
        return (
          <div className="fcrm-greed-col w-[150px]">
            <CopyableElementWrapper>{model.modelId}</CopyableElementWrapper>
          </div>
        );
      },
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: (
        <TableHeader
          title={'Email'}
          search
          key={ChatModelSearchEnum.EMAIL}
          name={ChatModelSearchEnum.EMAIL}
          searchValue={sellsSearch?.[ChatModelSearchEnum.EMAIL]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: 200,
      render: (_: any, model: any) => {
        return (
          <div className="fcrm-greed-col w-[200px]">
            <CopyableElementWrapper>{model.email}</CopyableElementWrapper>
          </div>
        );
      },
      sorter: true,
      sortOrder: checkSortOrder('email', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'subcriptionType',
      dataIndex: 'subcriptionType',
      title: <TableHeader title={'Subscription type'} />,
      width: 120,
      render: (value: string) => (
        <p className="fcrm-greed-col w-[120px]">{value}</p>
      ),
      sorter: true,
      sortOrder: checkSortOrder('subcriptionType', {
        field: sortField,
        order: sortOrder,
      }),
      filters: CREATOR_SUBSCRIPTION,
      filteredValue: filters?.subcriptionType || [],
    },
    {
      key: 'trialLinkCount',
      dataIndex: 'trialLinkCount',
      title: (
        <TableHeader
          title={'Trial Link'}
          search
          key={ChatModelSearchEnum.TRIAL_LINK_COUNT}
          name={ChatModelSearchEnum.TRIAL_LINK_COUNT}
          searchValue={sellsSearch?.[ChatModelSearchEnum.TRIAL_LINK_COUNT]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: 80,
      render: (value: number) => (
        <p className="fcrm-greed-col w-[80px]">{value}</p>
      ),
      sorter: true,
      sortOrder: checkSortOrder('trialLinkCount', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'trackingLinkCount',
      dataIndex: 'trackingLinkCount',
      title: (
        <TableHeader
          title={'Tracking Link'}
          search
          key={ChatModelSearchEnum.TRACKING_LINK_COUNT}
          name={ChatModelSearchEnum.TRACKING_LINK_COUNT}
          searchValue={sellsSearch?.[ChatModelSearchEnum.TRACKING_LINK_COUNT]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: 110,
      render: (value: number) => (
        <p className="fcrm-greed-col w-[110px]">{value}</p>
      ),
      sorter: true,
      sortOrder: checkSortOrder('trackingLinkCount', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'modelProxy',
      dataIndex: 'modelProxy',
      title: (
        <TableHeader
          title={'IP'}
          search
          key={ChatModelSearchEnum.IP}
          name={ChatModelSearchEnum.IP}
          searchValue={sellsSearch?.[ChatModelSearchEnum.IP]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: 150,
      render: (__: any, model: ChatModelType) => {
        return (
          <div className="fcrm-greed-col w-[150px]">
            <CopyableElementWrapper>
              {model.modelProxy?.mainProxy?.host
                ? model.modelProxy?.mainProxy?.host
                : 'none'}
            </CopyableElementWrapper>
          </div>
        );
      },
      sorter: true,
      sortOrder: checkSortOrder('modelProxy', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'company',
      dataIndex: 'company',
      title: (
        <TableHeader
          title={'Company'}
          search
          key={ChatModelSearchEnum.COMPANY_NAME}
          name={ChatModelSearchEnum.COMPANY_NAME}
          searchValue={sellsSearch?.[ChatModelSearchEnum.COMPANY_NAME]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: 200,
      sorter: true,
      sortOrder: checkSortOrder('company', {
        field: sortField,
        order: sortOrder,
      }),
      render: (company: any) => (
        <div className="fcrm-greed-col w-[200px]">
          <CopyableElementWrapper>
            {company?.name}
          </CopyableElementWrapper>
        </div>
      ),
    },
    {
      key: 'author',
      dataIndex: 'author',
      title: (
        <TableHeader
          title={'Author'}
          search
          key={ChatModelSearchEnum.AUTHOR}
          name={ChatModelSearchEnum.AUTHOR}
          searchValue={sellsSearch?.[ChatModelSearchEnum.AUTHOR]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: '12%',
      sorter: true,
      sortOrder: checkSortOrder('author', {
        field: sortField,
        order: sortOrder,
      }),
      render: (author: any) => (

        <div className="fcrm-greed-col w-[200px]">
          <CopyableElementWrapper
            onClick={() => {handleNavigateToAuthorWithParams(author?.username)}}
          >
            {author?.username}
          </CopyableElementWrapper>
        </div>
      ),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: <TableHeader title={'Status'} />,
      width: 200,
      render: (status: ModelStatusEnum) => {
        return (
          <div className="fcrm-greed-col w-[200px]">
            {status === ModelStatusEnum.ACTIVE && (
              <Tag color="success">{status}</Tag>
            )}
            {status === ModelStatusEnum.CONFIRMED && (
              <Tag color="processing">{status}</Tag>
            )}
            {status === ModelStatusEnum.OUTDATE && (
              <Tag color="gold">{status}</Tag>
            )}
            {[
              ModelStatusEnum.FAILED_CREDENTIALS,
              ModelStatusEnum.INACTIVE,
              ModelStatusEnum.UNKNOWN,
            ].includes(status) && (
              <Tag color="error">
                {status === ModelStatusEnum.UNKNOWN ? 'NEW' : status}
              </Tag>
            )}
          </div>
        );
      },
      sorter: true,
      sortOrder: checkSortOrder('status', {
        field: sortField,
        order: sortOrder,
      }),
      filters: CREATOR_STATUSES,
      filteredValue: filters?.status || [],
    },
    {
      key: 'chatters',
      dataIndex: 'chatters',
      title: (
        <TableHeader
          title={'Chatters'}
          search
          key={ChatModelSearchEnum.CHATER}
          name={ChatModelSearchEnum.CHATER}
          searchValue={sellsSearch?.[ChatModelSearchEnum.CHATER]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: 200,
      sorter: true,
      sortOrder: checkSortOrder('chatters', {
        field: sortField,
        order: sortOrder,
      }),
      render: (__: any, model: ChatModelType) => {
        return (
          <RowChatters
            modelId={model.id}
            modelName={model.name}
            companyName={model.company?.name}
            users={model.assignedUsers || []}
          />
        );
      },
    },
  ];

  return (
    <>
      <StoreChatModel
        maxTopPosition={150}
        onModelStored={refetch}
        publicKey={null}
        companyId={null}
      />
      {!loading && error && (
        <div className="fcrm-error-i">
          {error?.message || 'Internal error'}
        </div>
      )}
      <div className={styles.tableWrapper}>
        <Table
          loading={loading}
          columns={COLUMNS}
          data={data}
          setSort={setSortParams}
          currentPage={currentPage}
          setPage={setPageParams}
          pageLimit={
            paginationLimit ? paginationLimit : MODELS_PAGINATION_LIMIT
          }
          defPageSize={MODELS_PAGINATION_LIMIT}
          setPaginationLimit={setPaginationLimitParams}
          setFilters={setFilterParams}
          totalCount={pageData?.filterCount}
          emptyText="No creators available."
        />
      </div>
    </>
  );
};
