import {
  ChatModelType,
  CompanyType,
  GetChatModelByPageType,
  GetChatModelsInWorkByPagesType,
  GetCompaniesByPagesType,
  GetProxiesByPagesType,
  GetUsersType,
  ProxyType,
} from 'src/components';
import { config } from 'src/configs/config';
import { fromGlobalIdHelper } from './from-global-id.helper';
import { DateTime } from 'luxon';
import { PaginationType } from 'src/types';

export const dumpUsers = (
  data: GetUsersType,
): { list: any; pageInfo: PaginationType } => {
  const { nodes, pageInfo } = data.UserGroup.getUsersByPages;

  return {
    list:
      nodes?.map(
        ({
          id,
          email,
          chatModels,
          revenue,
          company,
          createdAt,
          lastLoginAt,
          emailConfirmedAt,
          status,
          role,
          username,
          autologinKey,
        }) => ({
          id,
          email,
          revenue: `$${revenue
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
          createdAt: createdAt
            ? new Date(createdAt).toLocaleDateString('ua', config.dateFormat)
            : 'n/a',
          lastLoginAt: lastLoginAt
            ? new Date(lastLoginAt).toLocaleDateString('ua', config.dateFormat)
            : 'n/a',
          creators: '',
          companyName: '',
          emailConfirmedAt: emailConfirmedAt,
          approvedAt: '',
          status: status,
          company: company,
          chatModels: chatModels,
          key: id,
          role: role,
          username: username,
          autologinKey: autologinKey,
        }),
      ) || [],
    pageInfo,
  };
};

export const dumpModels = (
  data: GetChatModelByPageType,
): { list: ChatModelType[]; pageInfo: PaginationType } => {
  const { nodes, pageInfo } = data.ChatModelGroup.getChatModelsByPages;

  const list = nodes?.reduce((acc, item) => {
    if (!item) return acc;

    if (acc.find(({ id }) => id === item.id)) {
      return acc;
    }

    return [
      ...acc,
      {
        ...item,
        key: item.id,
        modelId: fromGlobalIdHelper(item.id).id.toString() || '',
        subcriptionType: item.subscribeType,
        trialLinkCount: item.campaignsCount.trialLinkCount,
        trackingLinkCount: item.campaignsCount.trackingLinkCount,
      },
    ];
  }, [] as ChatModelType[]);

  return {
    list,
    pageInfo,
  };
};

export const dumpProxies = (
  data: GetProxiesByPagesType,
): { list: ProxyType[]; pageInfo: PaginationType } => {
  const { nodes, pageInfo } = data.Dictionary.getProxiesByPages;
  return {
    list: nodes?.map((item) => ({ ...item, key: item.id })) || [],
    pageInfo,
  };
};

export const dumpCompanies = (
  data: GetCompaniesByPagesType,
): { list: CompanyType[]; pageInfo: PaginationType } => {
  const { nodes, pageInfo } = data.AgencyGroup.getCompaniesByPages;

  return {
    list: nodes?.map((company) => ({ ...company, key: company.id })),
    pageInfo,
  };
};

export const dumpModelsInWork = (
  data: GetChatModelsInWorkByPagesType,
): { list: any[]; pageInfo: PaginationType } => {
  const { nodes, pageInfo } =
    data.ChatModelGroup.getAssignedUsersInWorkByPages;

  return {
    list: nodes?.map((item) => ({
      key: item.id,
      id: item.id,
      name: item.model?.name,
      startWorkAt: item.startWorkAt
        ? DateTime.fromISO(item.startWorkAt).toRelative()
        : '',
      chatter: item.user?.username,
    })),
    pageInfo,
  };
};
