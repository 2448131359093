import { ModelProps } from './typesListItem';
import {
  GET_USERS_BY_PAGES_QUERY,
  GetUsersType,
  NodesType,
  UserType,
} from '../user';
import { dumpDashboardSortParams } from 'src/utils/dump-sort-params.utils';
import Table, { CustomColumnType } from '../table';
import { config } from '../../configs/config';
import { RowActiveStatus } from './rowActiveStatus';
import { RowModels } from './rowModels';
import { RowActivity } from './rowActivity';
import { RowStatus } from './rowStatus';
import { RowApproved } from './rowApproved';
import { RowName } from './rowName';
import { Tag, Tooltip } from 'antd';
import { YELLOW } from 'src/constants/colors.constants';
import { RowCreators } from './rowCreators';
import { useSearchParamsHelper } from '../../hooks/useSearchParamsHelper';

import styles from './styles.module.css';
import { checkSortOrder } from 'src/utils/sort.utils';
import PageHeader from '../base/pageHeader';
import useQueryHook from 'src/hooks/useQueryHook.hook';
import { dumpUsers } from 'src/helpers/dump.helper';
import { RolesEnum, USERS_STATUSES } from 'src/types';
import { CopyableElementWrapper } from '../base/copyableElementWrapper ';

const PAGINATION_LIMIT = 10;

export const Dashboard = () => {
  const {
    setPageParams,
    setPaginationLimitParams,
    handleSetSearchParams,
    setSortParams,
    setFilterParams,
    canClear,
    clearAllFilters,
    filters,
    searchText,
    currentPage,
    paginationLimit,
    sortField,
    sortOrder,
  } = useSearchParamsHelper();

  const { data, loading, error, pageData, updateData, refetch } = useQueryHook<
    GetUsersType,
    NodesType
  >({
    query: GET_USERS_BY_PAGES_QUERY,
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: paginationLimit ? paginationLimit : PAGINATION_LIMIT,
      page: currentPage,
      searchStr: searchText,
      ...dumpDashboardSortParams({ field: sortField, order: sortOrder }),
      ...filters,
      roles: [RolesEnum.OWNER]
    },
    dumpFunction: dumpUsers,
  });

  const handleUpdateUsersData = (updatedUser: ModelProps) => {
    updateData((prev) => {
      if (!prev) return prev;

      return prev?.map((company) => ({
        ...company,
        chatModels: company.chatModels.map((creators) =>
          creators.id === updatedUser.id &&
          company.company[0].id === updatedUser.company.id
            ? {
                ...creators,
                ...updatedUser,
              }
            : creators,
        ),
      }));
    });
  };

  const COLUMNS: CustomColumnType = [
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Email',
      width: 210,
      sorter: true,
      sortOrder: checkSortOrder('email', {
        field: sortField,
        order: sortOrder,
      }),
      render: (__: any, row: UserType) => (
        <CopyableElementWrapper>{row.email}</CopyableElementWrapper>
      ),
    },
    {
      key: 'companyName',
      dataIndex: 'companyName',
      title: 'Company name',
      width: 250,
      render: (__: any, row: UserType) => {
        return <RowName rowData={row} />;
      },
      sorter: true,
      sortOrder: checkSortOrder('companyName', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'creators',
      dataIndex: 'creators',
      title: 'Creators',
      width: 100,
      render: (__: any, row: UserType) => (
        <RowCreators row={row} labelColor={YELLOW} />
      ),
      sorter: true,
      sortOrder: checkSortOrder('creators', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'revenue',
      dataIndex: 'revenue',
      title: 'Revenue 30 days',
      width: 190,
      sorter: true,
      sortOrder: checkSortOrder('revenue', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Registered at',
      width: 130,
      sorter: true,
      sortOrder: checkSortOrder('createdAt', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'lastLoginAt',
      dataIndex: 'lastLoginAt',
      title: 'Last active at ',
      width: 130,
      sorter: true,
      sortOrder: checkSortOrder('lastLoginAt', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'emailConfirmedAt',
      dataIndex: 'emailConfirmedAt',
      title: 'Email confirmed',
      width: 140,
      render: (__: any, row: UserType) => (
        <div>
          {row.emailConfirmedAt ? (
            new Date(row.emailConfirmedAt).toLocaleDateString(
              'ua',
              config.dateFormat,
            )
          ) : (
            <Tag style={{ color: YELLOW, borderColor: YELLOW }}>
              not confirmed
            </Tag>
          )}
        </div>
      ),
      sorter: true,
      sortOrder: checkSortOrder('emailConfirmedAt', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'approvedAt',
      dataIndex: 'approvedAt',
      title: 'Approved at',
      width: 128,
      render: (__: any, row: UserType) => <RowApproved userData={row} />,
      sorter: true,
      sortOrder: checkSortOrder('approvedAt', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
      ellipsis: true,
      width: 128,
      render: (__: any, row: UserType) => (
        <RowStatus userData={row} onUpdate={refetch} />
      ),
      sorter: true,
      sortOrder: checkSortOrder('status', {
        field: sortField,
        order: sortOrder,
      }),
      filters: USERS_STATUSES,
      filteredValue: filters.status || [],
    },
    {
      key: 'activity',
      dataIndex: 'activity',
      title: 'Activity',
      width: 128,
      render: (__: any, row: UserType) => <RowActivity userData={row} />,
      ellipsis: true,
      sorter: true,
      sortOrder: checkSortOrder('activity', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'chatModels',
      dataIndex: 'chatModels',
      title: 'Creators list',
      width: 120,
      render: (__: any, row: UserType) => (
        <RowModels
          modelsData={row.chatModels}
          companyEmail={row.email}
          handleUpdateUsersData={handleUpdateUsersData}
        />
      ),
      sorter: true,
      sortOrder: checkSortOrder('chatModels', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'modelsUsage',
      dataIndex: 'modelsUsage',
      title: () => (
        <Tooltip title="Models usage activity.">Active status</Tooltip>
      ),
      width: 120,
      render: (__: any, row: UserType) => <RowActiveStatus row={row} />,
    },
  ];

  return (
    <div className={styles.container}>
      <PageHeader
        title="Dashboard"
        setSearch={handleSetSearchParams}
        search={searchText}
        clear={canClear}
        onClear={clearAllFilters}
      />

      <div className={styles.wrapper}>
        <Table
          loading={loading}
          columns={COLUMNS}
          data={data}
          setSort={setSortParams}
          currentPage={currentPage}
          setPage={setPageParams}
          pageLimit={paginationLimit ? paginationLimit : PAGINATION_LIMIT}
          defPageSize={PAGINATION_LIMIT}
          setPaginationLimit={setPaginationLimitParams}
          filters={filters}
          setFilters={setFilterParams}
          totalCount={pageData?.totalCount}
          emptyText="No users registered"
        />

        {!loading && error && (
          <div className="fcrm-error-i">
            {error?.message || 'Internal error'}
          </div>
        )}
      </div>
    </div>
  );
};
